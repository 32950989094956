<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          :title="subject +' '+ infoFormulaire.objet"
        >
          <v-textarea
            v-model="infoFormulaire.message"
            class="purple-input"
            label=""
            :disabled="true"
          >
          </v-textarea>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          title="Demande de contact  - Information Contact"
        >
          <v-list>
             <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ infoFormulaire.nom }}  {{ infoFormulaire.prenom }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-divider inset />
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ infoFormulaire.telephone }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-email</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ infoFormulaire.mail }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

          <!-- <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Orlando, FL 79938</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          </v-list>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'

  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      subject: 'Demande de contact  - ',
      produitsTmp: [],
      note: '',
      statusCommandeSelected: [{
        statutId: 1,
      }],
      FromUser: false,
      infoUser: '',
      InformationNumeroCommande: 'Informations commande',
      infoClient: '',
      updateOk: false,
      KOupdate: false,
      envoiMailPreparationOK: false,
      envoiMailPreparationKO: false,
      envoiMailEnvoiCommandeOK: false,
      envoiMailEnvoiCommandeKO: false,
      colorSnackbarSuccess: 'success',
      colorSnackbarError: 'error',
      infoFormulaire: {},
      statutItem: 'Publié',
      produits: [],
      userId: '',
      infoCommandeBDD: [],
      direction: 'bottom right',
      status: [{
        statutId: 1,
        statutLabel: 'En attente de paiement',
      }, {
        statutId: 2,
        statutLabel: 'Payé',
      }, {
        statutId: 3,
        statutLabel: 'Préparation',
      }, {
        statutId: 4,
        statutLabel: 'Pris en charge',
      }, {
        statutId: 5,
        statutLabel: 'Annulé',
      }, {
        statutId: 6,
        statutLabel: 'Supprimé',
      },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    mounted () {
      this.infoFormulaire = this.$route.params.infoFormulaire
      console.log(this.infoFormulaire)
    },
    created () {
      // this.infoFormulaire.objet = ' Livraison Outre mer '
      // this.subject = this.subject + this.infoFormulaire.objet
      // this.infoFormulaire.message = ' Message test'
      // this.infoFormulaire.telephone = ' 0909090909 '
      // this.infoFormulaire.mail = ' publicom@publicom.fr '
    },
    methods: {
      GoDetailsUser: function (userID) {
        this.$router.push({ name: 'UsersDetails', params: { userID: userID, test: 'ok' } })
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      ModifierCommande: function (infoCommande) {
        Vue.axios.put('https://api.espace-jantes.com/updateOrders', {
          params: { infoCommande: infoCommande[0], infoProduits: this.produitsTmp },
        })
          .then((response) => {
            if (response.data.etat) {
              this.updateOk = true
              this.colorSnackbarSuccess = 'success'
            } else {
              this.KOUpdate = true
              this.colorSnackbarError = 'error'
            }
          })
          .catch(error => console.log(error))
      },
      modifierNote: function (note) {
        // console.log(note)
      },
    },
  }
</script>
<style scoped>
  input:focus {
    border: 2px solid black !important;
    border-radius : 4px;
  }
  th{
    font-size: 14px !important;
  }
</style>
