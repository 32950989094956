import { render, staticRenderFns } from "./OneFormulaire.vue?vue&type=template&id=61171dc1&scoped=true&"
import script from "./OneFormulaire.vue?vue&type=script&lang=js&"
export * from "./OneFormulaire.vue?vue&type=script&lang=js&"
import style0 from "./OneFormulaire.vue?vue&type=style&index=0&id=61171dc1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61171dc1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VContainer,VDivider,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VRow,VTextarea})
